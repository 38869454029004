import React from "react"
import {useIntl} from "gatsby-plugin-intl"
//Icons
import { BiCodeBlock } from "react-icons/bi"

//Components
import Layout from "../components/layout"
import Seo from "../components/seo"

//Styles
import skillsStyles from "./skills.module.scss"
export default () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo title={intl.formatMessage({id: "skills__title"})}/>
      <section className={skillsStyles.skills}>
        <section className={skillsStyles.light}>
          <h1>{intl.formatMessage({id: "skills__title"})}</h1>
          <p>{intl.formatMessage({id: "skills__content"})}</p>
        </section>
        <section className={skillsStyles.dark}>
          <section className={skillsStyles.skillsContainer}>
            <BiCodeBlock className={skillsStyles.logo} />
            <ul className={skillsStyles.skillsList}>
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>JSON</li>
              <li>npm</li>
            </ul>
          </section>
        </section>
      </section>
    </Layout>
  )
}
